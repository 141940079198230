import React, { useState, useEffect } from 'react';
// import { closeDrawer, openDrawer } from '../../actions/drawerActions';
import { useDispatch, useSelector } from 'react-redux';
import './ShieldRecommendation.scss';
import Spinner from '../Spinner/Spinner';
import { addZeroes } from "../../Utilities/AddZeros";
import { findClosest } from "../../Utilities/ClosestPercent";
// import { formatContract } from "../../Utilities/FormatContract";
// import { GetMarketShield } from '../../Apis/GetMarketShield';
import { GetMarketShieldPlus } from '../../Apis/GetMarketShieldPlus';
// import { GetQuoteEngineData } from '../../Apis/GetQuoteEngineData';
import { GetAssetAllocationEquivalence } from '../../Apis/GetAssetAllocationEquivalenceData';
import AnalyticsDrawer from "../../Components/AnalyticsDrawer/AnalyticsDrawer";
import { handleApiError } from '../../actions/notificationActions';
import Toaster from '../../Components/Toaster/Toaster';
// import TagManager from 'react-gtm-module';
import TooltipComponent from '../TooltipComponent/TooltipComponent';
import ShoppingCart from './ShoppingCart';
import DatePicker from 'react-datepicker';
import moment from 'moment';


function ShieldRecommendation() {

    const DRAWER_COMPONENTS = {
        Analytics: AnalyticsDrawer
    };
    const dispatch = useDispatch();
    const drawerData = useSelector(state => state.drawer.data);
    const portfolioId = drawerData.portfolioId;
    const isEmpty = drawerData.isEmpty;
    const [loadingState, setLoadingState] = useState({
        marketShield: false,
        marketShieldPlus: false,
        guaranteedShield: false
    });

    
    const getThirdFriday = (date) => {
        let month = date.month();
        let year = date.year();

        let firstDay = moment([year, month, 1]);
        let firstFriday = firstDay.day() <= 5 ? firstDay.day(5) : firstDay.add(1, 'weeks').day(5);

        return firstFriday.add(2, 'weeks');
    };

    const [defaultDate, setDefaultDate] = useState(getThirdFriday(moment().add(1, 'months')));

    const [shields, setShields] = useState({
        marketShield: {
            protectionLevel: 90,
            protectionPeriod: defaultDate.toDate(),
            assetAllocation: "95-5", 
        },
        marketShieldPlus: {
            protectionLevel: 90,
            protectionPeriod: defaultDate.toDate(),
            assetAllocation: "95-5", 
        },
        guaranteedShield: {
            protectionLevel: 90,
            protectionPeriod: "1 Month",
            assetAllocation: "95-5", 
        }
    });

    const [apiPeriods, setApiPeriods] = useState({
        marketShield: "",
        marketShieldPlus: "",
    });
    
    const [marketShieldData, setMarketShieldData] = useState([])
    const [marketShieldPlusData, setMarketShieldPlusData] = useState([])
    const [guaranteedShieldData, setGuaranteedShieldData] = useState([])
    const [marketShieldCart, setMarketShieldCart] = useState([])
    const [marketShieldPlusCart, setMarketShieldPlusCart] = useState([])
    const [shoppingFlag, setShoppingFlag] = useState(false)
    const [shieldPrice, setShieldPrice] = React.useState(0);
    const [selectedPercent, setSelectedPercent] = React.useState(0);
    const [allocationMode, setAllocationMode] = useState(false);
    const [allocationData, setAllocationData] = useState([]);
    const totalPremium = sessionStorage.getItem('totalPremium');
    console.log("totalPremium", totalPremium)
    const totalCost = sessionStorage.getItem('totalCost');
    const portfolioDetails = useSelector(state => state.portfolioDetails[portfolioId].details);
    const [dynamicProtectionLevels, setDynamicProtectionLevels] = useState({});

    var currObj = {
        style: "currency",
        currency: "USD",
    };


    const periods_dict_backend = {
        "1 Month": "month",
        "2 Months": "month_2",
        "3 Months": "quarter",
        "6 Months": "half_year",
        "Year": "year",
    };



    // Generic function to update a shield's key based on shield type
    const updateShield = (shieldType, key, value) => {
        setShields(prevShields => ({
            ...prevShields,
            [shieldType]: {
                ...prevShields[shieldType],
                [key]: value
            }
        }));
    };

    function fetch_price(shield_data) {
        console.log(" shie", shield_data);
        try {
            let percent = findClosest(shield_data[periods_dict_backend[shields["guaranteedShield"]["protectionPeriod"]]],
                shields.guaranteedShield.protectionLevel);
            var shieldPercent = addZeroes(Number((100 - percent).toFixed(2).toString()));
            setSelectedPercent(shieldPercent);
            percent = (percent / 100).toFixed(2);
            let shieldprice = (shield_data[periods_dict_backend[shields["guaranteedShield"]["protectionPeriod"]]][shieldPercent]["shieldprice"]).toFixed(2);
            setShieldPrice(shieldprice);
        } catch (error) {
            console.log("Error while setting gaurantee shield", error)
        }
    }

    useEffect(() => {
        if (isEmpty) return;
        console.log('Shields state updated:', shields);
    }, [shields]);

    const fetchShieldData = (formattedDate, shieldsToUpdate = ['marketShield', 'marketShieldPlus']) => {
        console.log(" in fetch shield")
        setLoadingState((prev) => {
            const newState = { ...prev };
            shieldsToUpdate.forEach((shield) => {
                newState[shield] = true;
            });
            return newState;
        });
    
        GetMarketShieldPlus(portfolioId, formattedDate)
            .then((data) => {
                if (data.status === 200) {
                    console.log("Shield data fetched successfully:", data.result);
    
                    const periods = Object.keys(data.result.ChoosenOption);
                    const protectionLevels = periods.reduce(
                        (acc, period) => ({
                            ...acc,
                            [period]: Object.keys(data.result.ChoosenOption[period])
                                .map(Number)
                                .sort((a, b) => b - a),
                        }),
                        {}
                    );
                    setDynamicProtectionLevels((prev) => ({
                        ...prev,
                        [shieldsToUpdate.includes('marketShield') ? 'marketShield' : '']: protectionLevels[periods[0]],
                        [shieldsToUpdate.includes('marketShieldPlus') ? 'marketShieldPlus' : '']: protectionLevels[periods[0]]
                    }));
    
                    shieldsToUpdate.forEach((shield) => {
                        const shieldData = shield === 'marketShield' ? marketShieldData : marketShieldPlusData;
                        const closestLevel = protectionLevels[periods[0]].reduce(
                            (prev, curr) =>
                                Math.abs(curr - 0.95) < Math.abs(prev - 0.95) ? curr : prev
                        );
    
                        updateShield(shield, "protectionPeriod", new Date(formattedDate));
                        updateShield(shield, "protectionLevel", closestLevel);

                        if (shield === "marketShield") {
                            setMarketShieldData(data.result);
                            setApiPeriods((prev) => ({
                                ...prev,
                                marketShield: periods[0],
                            }));
                        } else if (shield === "marketShieldPlus") {
                            setMarketShieldPlusData(data.result);
                            setApiPeriods((prev) => ({
                                ...prev,
                                marketShieldPlus: periods[0],
                            }))
                        }
                    });
                } else {
                    handleApiError(data, dispatch);
                }
    
                setLoadingState((prev) => {
                    const newState = { ...prev };
                    shieldsToUpdate.forEach((shield) => {
                        newState[shield] = false;
                    });
                    return newState;
                });
            })
            .catch((error) => {
                console.error("Error fetching shield data:", error);
                handleApiError(error, dispatch);
                setLoadingState((prev) => {
                    const newState = { ...prev };
                    shieldsToUpdate.forEach((shield) => {
                        newState[shield] = false;
                    });
                    return newState;
                });
            });
    };

    const fetchAllocationData = (formattedDate, shieldsToUpdate = ['marketShield', 'marketShieldPlus']) => {
        GetAssetAllocationEquivalence(portfolioId, formattedDate).then((data) => {
            if (data.status === 200) {
                console.log("Asset allocation data fetched successfully:", data.result);
    
                shieldsToUpdate.forEach((shield) => {
                    setAllocationData((prev) => ({
                        ...prev,
                        [shield]: data.result
                    }));
                });
    
            } else {
                handleApiError(data, dispatch);
            }
        }).catch(error => {
            console.error("Error fetching allocation data:", error);
            handleApiError(error, dispatch);
        });
    };
    

    useEffect(() => {
        if (!isEmpty && portfolioId) {
            const formattedDate = moment(shields.marketShield.protectionPeriod).format('YYYY-MM-DD');
            fetchShieldData(formattedDate);
            fetchAllocationData(formattedDate);
        }
    }, [isEmpty, portfolioId]);
    

    const renderAllocationOptions = (shieldType) => {
        const allocationOptions = Object.keys(allocationData[shieldType]?.Allocation || {});
        return allocationOptions.map(option => (
            <option key={option} value={option}>{option}</option>
        ));
    };

    useEffect(() => {
        if (isEmpty) return;
        if (guaranteedShieldData.length === 0) {
            setLoadingState(prev => ({ ...prev, guaranteedShield: true }));
            // GetQuoteEngineData(portfolioId).then((quote_data) => {
            //     if (quote_data.status === 200) {
            //         console.log(" quote data", quote_data.result);
            //         setGuaranteedShieldData(quote_data.result);
            //         fetch_price(quote_data.result);
            //         setLoadingState(prev => ({ ...prev, guaranteedShield: false }));
            //     } else {
                    //handleApiError(quote_data, dispatch);
            setLoadingState(prev => ({ ...prev, guaranteedShield: false }));
            //     }
            // });
        }
        else if (shields.guaranteedShield.protectionLevel || shields.guaranteedShield.protectionPeriod) {
            fetch_price(guaranteedShieldData);
        }
    }, [isEmpty, shields.guaranteedShield.protectionLevel, shields.guaranteedShield.protectionPeriod]);
    

    useEffect(() => {
        if (Object.keys(allocationData).length > 0) {
            ["marketShield", "marketShieldPlus", "guaranteedShield"].forEach((shieldType) => {
                const allocation = shields[shieldType].assetAllocation;
                const equivalentLevel = calculateEquivalentProtectionLevel(allocation);
    
                if (shields[shieldType].equivalentProtectionLevel !== equivalentLevel) {
                    updateShield(shieldType, "equivalentProtectionLevel", equivalentLevel);
                }
            });
        }
    }, [allocationData]);
    

    const createShoppingCartData = (shieldType, period, level) => {
        console.log("Creating shopping cart data", shieldType, period, level);
    
        try {
            const shieldData = shieldType === "marketShield" ? marketShieldData : marketShieldPlusData;
            const shieldApiPeriod = apiPeriods[shieldType]; // Use the specific period for the shield type
    
            if (!shieldData || !shieldData.ChoosenOption || !shieldData.ChoosenOption[shieldApiPeriod]) {
                console.error(`Shield data is not properly loaded or empty for ${shieldType}.`);
                return [];
            }
    
            let chosenData = shieldData.ChoosenOption[shieldApiPeriod][level];
            if (!chosenData) return [];
    
            // Handle different data structure for Market Shield and Market Shield Plus
            let contracts = [], quantities = [], price;
    
            if (shieldType === "marketShieldPlus" && chosenData.StockOptions) {
                // Extract data from StockOptions for Market Shield Plus
                contracts = chosenData.StockOptions.SecDesc || [];
                quantities = chosenData.StockOptions.ConQty || [];
                price = chosenData.cost_with_stock || "-";
    
                // Merge Market Shield contracts into Market Shield Plus cart
                const marketShieldCart = createShoppingCartData("marketShield", period, level);
                marketShieldCart.forEach(item => {
                    contracts.push(item.contract);
                    quantities.push(item.quantity);
                });
    
            } else {
                // Handle Market Shield or when StockOptions are not present in Market Shield Plus
                contracts = chosenData.SecDesc || [];
                quantities = chosenData.ConQty || [];
                price = shieldType === "marketShield" ? chosenData.Cost || "-" : chosenData.cost_with_stock || "-";
            }
    
            // Construct shopping cart data
            return contracts.map((contract, index) => ({
                contract: contract,
                quantity: quantities[index] || '-',
                protectionLevel: shieldType === "marketShield" ? level : undefined,
                price: price,
            }));
        } catch (err) {
            console.error("Error in createShoppingCartData:", err);
            return [];
        }
    };
    
    // const createShoppingCartData = (shieldType, period, level) => {
    //     try {
    //         const shieldData = shieldType === "marketShield" ? marketShieldData : marketShieldPlusData;
    //         const shieldApiPeriod = apiPeriods[shieldType];
    
    //         if (!shieldData || !shieldData.ChoosenOption || !shieldData.ChoosenOption[shieldApiPeriod]) {
    //             console.error(`Shield data is not properly loaded or empty for ${shieldType}.`);
    //             return [];
    //         }
    
    //         let chosenData = shieldData.ChoosenOption[shieldApiPeriod][level];
    //         if (!chosenData) return [];
    //         let contracts, quantities, price;
    
    //         if (shieldType === "marketShieldPlus" && chosenData.StockOptions) {
    //             contracts = chosenData.StockOptions.SecDesc || [];
    //             quantities = chosenData.StockOptions.ConQty || [];
    //             price = chosenData.cost_with_stock || "-";
    //         } else {
    //             contracts = chosenData.SecDesc || [];
    //             quantities = chosenData.ConQty || [];
    //             price = shieldType === "marketShield" ? chosenData.Cost || "-" : chosenData.cost_with_stock || "-";
    //         }

    //         return contracts.map((contract, index) => ({
    //             contract: contract,
    //             quantity: quantities[index] || '-',
    //             price: price,
    //         }));
    //     } catch (err) {
    //         console.error("Error in createShoppingCartData:", err);
    //         return [];
    //     }
    // };
    
    while (marketShieldCart.length < marketShieldPlusCart.length) {
        marketShieldCart.push({ contract: '-', quantity: '-' });
    }
    while (marketShieldPlusCart.length < marketShieldCart.length) {
        marketShieldPlusCart.push({ contract: '-', quantity: '-' });
    }
    
    const calculateEquivalentProtectionLevel = (shieldType, assetAllocation) => {
        const allocation = allocationData[shieldType]?.Allocation || {};
        const protLevelFloat = allocation[assetAllocation]?.ProtLevelFloat;
    
        if (!protLevelFloat) return ""; 
    
        const roundedValue = parseFloat(protLevelFloat).toFixed(4);
        console.log("Rounded Value:", roundedValue);
        
        return roundedValue;
    };

    const findClosestProtLevel = (shieldType, assetAllocation) => {
        if (!allocationData || !allocationData[shieldType] || !allocationData[shieldType].Allocation) {
            console.error(`Allocation data is not defined or empty for shield type: ${shieldType}.`);
            return null;
        }
    
        const allocationDetail = allocationData[shieldType].Allocation[assetAllocation];

        if (!allocationDetail) {
            console.error(`No allocation details found for ${shieldType} with allocation: ${assetAllocation}`);
            return null;
        }
    
        const equivalentProtectionLevel = parseFloat(allocationDetail.ProtLevelFloat);
        if (isNaN(equivalentProtectionLevel)) {
            console.error("Invalid equivalent protection level:", equivalentProtectionLevel);
            return null;
        }
        const shieldData = shieldType === 'marketShield' ? marketShieldData : marketShieldPlusData;
        const shieldApiPeriod = apiPeriods[shieldType]
        const shieldLevels = Object.keys(shieldData?.ChoosenOption?.[shieldApiPeriod] || {}).map(key => parseFloat(key));
    
        if (!shieldLevels.length) {
            console.error(`Shield data is not properly loaded or empty for ${shieldType}.`);
            return null;
        }
        const closestLevel = shieldLevels.reduce((prev, curr) =>
            Math.abs(curr - equivalentProtectionLevel) < Math.abs(prev - equivalentProtectionLevel) ? curr : prev
        );
    
        console.log(`Closest equivalent level for ${shieldType}:`, closestLevel.toFixed(4));
        return closestLevel.toFixed(4);
    };
    
    const handleModeSwitch = () => {
        const newMode = !allocationMode;
        setAllocationMode(newMode);
    
        if (!newMode) {
            ["marketShield", "marketShieldPlus"].forEach((shieldType) => {
                const assetAllocation = shields[shieldType].assetAllocation;
                console.log(`Switching mode for ${shieldType}. Current asset allocation: ${assetAllocation}`);
                
                const closestLevel = findClosestProtLevel(shieldType, assetAllocation);
                console.log(`New mode for ${shieldType}. Closest protection level: ${closestLevel} for allocation: ${assetAllocation}`);
    
                if (closestLevel) {
                    updateShield(shieldType, "protectionLevel", closestLevel);
                }
            });
        }
    };
    
    console.log(" shields ", shields)
    const cardData = [
        {
            id: 1,
            title: 'Market Shield',
            key: "marketShield",
            protection_period: shields["marketShield"]["protectionPeriod"],
            protection_level: shields["marketShield"]["protectionLevel"],
            allocation: shields["marketShield"]["assetAllocation"],
            equivalentProtectionLevel: calculateEquivalentProtectionLevel('marketShield', shields["marketShield"]["assetAllocation"]),
            trackingError: marketShieldData["TrackingError"] * 100 || null,
            closestMatchingIndex: marketShieldData["PrimaryHolding"] || null,
            rSquare: marketShieldData["RSq"] || null,
            beta: marketShieldData["Beta"] || null,
            price: allocationMode && findClosestProtLevel('marketShield', shields["marketShield"]["assetAllocation"])
            ? marketShieldData?.ChoosenOption?.[apiPeriods.marketShield]?.[findClosestProtLevel('marketShield', shields["marketShield"]["assetAllocation"])]?.Cost || "-"
            : marketShieldData?.ChoosenOption?.[apiPeriods.marketShield]?.[shields["marketShield"]["protectionLevel"]]?.Cost || "-",
        
            concentratedHolding: "-",
            check: "",
            totalPremium: totalPremium,
            totalCost: totalCost,
            netCost: `${totalPremium - totalCost < 0 ? '-' : ''}${Number(addZeroes(Number(parseFloat(Math.abs(totalPremium - totalCost)).toFixed(2)))).toLocaleString("en-US", currObj)} (${((totalPremium - totalCost) / portfolioDetails.TotalPortfolioValue * 100).toFixed(2)}%)`
        },
        {
            id: 2,
            title: 'Market Shield Plus',
            key: 'marketShieldPlus',
            protection_period: shields["marketShieldPlus"]["protectionPeriod"],
            protection_level: shields["marketShieldPlus"]["protectionLevel"],
            allocation: shields["marketShieldPlus"]["assetAllocation"],
            equivalentProtectionLevel: calculateEquivalentProtectionLevel('marketShieldPlus', shields["marketShieldPlus"]["assetAllocation"]),
            trackingError: marketShieldPlusData["TrackingError"] * 100 || null,
            closestMatchingIndex: marketShieldPlusData["PrimaryHolding"],
            rSquare: marketShieldPlusData["RSq"] || null,
            beta: marketShieldPlusData["Beta"] || null,
            price: allocationMode && findClosestProtLevel('marketShieldPlus', shields["marketShieldPlus"]["assetAllocation"])
            ? marketShieldPlusData?.ChoosenOption?.[apiPeriods.marketShieldPlus]?.[findClosestProtLevel('marketShieldPlus', shields["marketShieldPlus"]["assetAllocation"])]?.cost_with_stock || "-"
            : marketShieldPlusData?.ChoosenOption?.[apiPeriods.marketShieldPlus]?.[shields["marketShieldPlus"]["protectionLevel"]]?.cost_with_stock || "-",
            concentratedHolding: marketShieldPlusData["OtherHolding"] || null,
            check: periods_dict_backend[shields["marketShieldPlus"]["protectionPeriod"]],
            totalPremium: totalPremium,
            totalCost: totalCost,
            netCost: `${totalPremium - totalCost < 0 ? '-' : ''}${Number(addZeroes(Number(parseFloat(Math.abs(totalPremium - totalCost)).toFixed(2)))).toLocaleString("en-US", currObj)} (${((totalPremium - totalCost) / portfolioDetails.TotalPortfolioValue * 100).toFixed(2)}%)`
        }
    ];
    //     // {
    //     //     id: 3,
    //     //     title: 'Portfolio Shield',
    //     //     key: "guaranteedShield",
    //     //     protection_period: shields["guaranteedShield"]["protectionPeriod"],
    //     //     protection_level: shields["guaranteedShield"]["protectionLevel"],
    //     //     allocation: shields["guaranteedShield"]["assetAllocation"],
    //     //     equivalentProtectionLevel: "",
    //     //     trackingError: 0,
    //     //     closestMatchingIndex: "-",
    //     //     rSquare: "1%",
    //     //     beta: "-",
    //     //     price: shieldPrice ? shieldPrice : "-",
    //     //     concentratedHolding: "-",
    //     //     check: periods_dict_backend[shields["guaranteedShield"]["protectionPeriod"]],
    //     //     totalPremium: totalPremium,
    //     //     totalCost: totalCost,
    //     //     netCost: `${totalPremium - totalCost < 0 ? '-' : ''}${Number(addZeroes(Number(parseFloat(Math.abs(totalPremium - totalCost)).toFixed(2)))).toLocaleString("en-US", currObj)} (${((totalPremium - totalCost) / portfolioDetails.TotalPortfolioValue * 100).toFixed(2)}%)`


    //     // },
    // ];
    console.log("cardData", cardData, marketShieldPlusData, allocationData)
    const handleProtectionLevelChange = (shieldType, value) => {
        updateShield(shieldType, "protectionLevel", value);
    };


    useEffect(() => {
        
        console.log(" shields in caert", shields)
        const marketShieldCart = createShoppingCartData(
            "marketShield",
            shields["marketShield"]["protectionPeriod"],
            shields["marketShield"]["protectionLevel"]
        );
        console.log(" ms cart ", marketShieldCart)
        setMarketShieldCart(marketShieldCart);
        sessionStorage.setItem('marketShieldCart', JSON.stringify(marketShieldCart));

    }, [
        shields.marketShield.protectionLevel,
        shields.marketShield.protectionPeriod,
        marketShieldData,
        shields.marketShield.assetAllocation,
        allocationMode
    ]);


    useEffect(() => {
        const marketShieldCart = createShoppingCartData(
            "marketShieldPlus",
            shields["marketShieldPlus"]["protectionPeriod"],
            shields["marketShieldPlus"]["protectionLevel"]
        );
        setMarketShieldPlusCart(marketShieldCart);
        sessionStorage.setItem('marketShieldPlusCart', JSON.stringify(marketShieldCart));
    }, [
        shields.marketShieldPlus.protectionPeriod,
        marketShieldPlusData,
        shields.marketShieldPlus.protectionLevel,
        shields.marketShieldPlus.assetAllocation,
        allocationMode
    ]);


    const handleProtectionPeriodChange = (shieldType, valueOrDate) => {
        if (!valueOrDate || isNaN(valueOrDate)) return;
        const formattedDate = moment.utc(valueOrDate).format('YYYY-MM-DD');
        updateShield(shieldType, "protectionPeriod", moment.utc(valueOrDate).startOf('day').toDate());
        fetchShieldData(formattedDate, [shieldType]);
        fetchAllocationData(formattedDate, [shieldType]);
    };

    const handleAssetAllocationChange = (shieldType, value) => {
        updateShield(shieldType, "assetAllocation", value);
        const equivalentLevel = calculateEquivalentProtectionLevel(value);
        updateShield(shieldType, "equivalentProtectionLevel", equivalentLevel)
    };

    return (
        <>
            <div className="toast-container">
                <Toaster />
            </div>
            <div className="drawercontent">
                {(!shoppingFlag) ? (
                    <div className='shieldrecc'>
                        <div className="card-container">
                            <div className="header-card">
                                <div>Protection Type</div>
                                <div>Protection Period</div>
                                {/* <div> */}
                                <div className="slider-container" onClick={handleModeSwitch}>
                                    {/* <span className={`background ${allocationMode ? 'left' : 'right'}`}></span> */}
                                    <div className={`pill ${allocationMode ? '' : 'active'}`}>Protection Level</div>
                                    <div className={`pill ${allocationMode ? 'active' : ''}`}>Asset Allocation <TooltipComponent id={'Asset Allocation'}/></div>
                                </div>


                                <div>Portfolio Analytics</div>
                                <div>Protection Price</div>
                                <div>Premium Covered Calls</div>
                                <div>Cost Individual Puts</div>
                                <div>Net Cost/Income</div>
                                
                            </div>
                            <div className='cardscontainer'>
                            {cardData.map(card => (
                                <div key={card.id} className="card">
                                    {loadingState[card.key] ?
                                        <div className="spinner-container">
                                            <Spinner variant="dark" size="big"/> 
                                        </div> :
                                        <>
                                            <div><h4 className="card-title">{card.title}</h4></div>
                                            <div>
                                                    <DatePicker
                                                        selected={moment.utc(shields[card.key].protectionPeriod).toDate()}
                                                        onChange={(date) => handleProtectionPeriodChange(card.key, moment.utc(date).startOf('day').toDate())}
                                                        dateFormat="yyyy-MM-dd"
                                                    />
                                            </div>

                                            {/* <div>
                                            {console.log("shields[card.key].protectionPeriod", shields, shields[card.key], shields[card.key].protectionPeriod)}
                                            <DatePicker
                                                selected={shields[card.key].protectionPeriod}
                                                onChange={(date) => handleProtectionPeriodChange(card.key, date)}
                                                dateFormat="yyyy-MM-dd"
                                            />
                                            {/* <select className="dropdown" value={periods_dict[card.protection_period]} onChange={(e) => handleProtectionPeriodChange(card.key, e.target.value)}>
                                                {Object.entries(periods_dict).map(([key, value]) => (
                                                    <option key={value} value={value}>{key}</option>
                                                ))}
                                            </select> */}
                                            {/* </div>  */}
                                            <div>
                                                {allocationMode ? (
                                                    <>
                                                        <div flex-col>
                                                            <select className="dropdown" value={card.allocation} onChange={(e) => handleAssetAllocationChange(card.key, e.target.value)}>
                                                                {renderAllocationOptions(card.key)}
                                                            </select>
                                                            {card.equivalentProtectionLevel !== "" ? <div style={{ fontSize: 11 }}>Equivalent protection level {parseFloat(card.equivalentProtectionLevel).toFixed(2) * 100}%</div> : null}
                                                        </div>
                                                    </>
                                                ) : (
                                            <div>
                                                <select className="dropdown" value={shields[card.key].protectionLevel} onChange={(e) => handleProtectionLevelChange(card.key, e.target.value)}>
                                                    {console.log(" in console ", card.key, shields)}
                                                    {dynamicProtectionLevels[card.key]?.map(option => (
                                                            <option key={option} value={option}>
                                                                {(option * 100).toFixed(2)}%
                                                            </option>
                                                    ))}
                                                </select>
                                            </div>
                                                )}
                                            </div>

                                            <div><div className="tracking-error">Tracking Error {card.trackingError?parseFloat(card.trackingError).toFixed(2)+' %':'NA'} </div></div>
                                            <div><h3 className="price">
                                                {card.price === '-' ? "No Price Available" : Number(
                                                    addZeroes(Number(parseFloat(card.price).toFixed(2)))
                                                ).toLocaleString("en-US", currObj)+` (${((card.price) / portfolioDetails.TotalPortfolioValue * 100).toFixed(2)}%)`}</h3></div>
                                            <div>${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(card.totalPremium) || 0)}</div>
                                            <div>${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(card.totalCost) || 0)}</div>
                                            <div>{card.netCost}</div>
                                        </>
                                    }
                                </div>
                            ))}
                            </div>
                        </div>

                        <div className="center-button-container">
                            <button className="ai-btn line primary" onClick={() => {
                                setShoppingFlag(true);
                            }}><img src="Assets/shopping-cart.svg" alt="toggle" /> Show Shopping Cart</button>
                        </div>

                        <div className="card-container">
                            <div className="header-card">
                                <div>Protection Type</div>
                                <div>Closest Matching Index <TooltipComponent id={'Closest Matching Index'}/></div>
                                <div>Goodness of fit (R-sq)</div>
                                <div>Tracking Error</div>
                                <div>Beta</div>
                                <div>Concentrated Holdings<TooltipComponent id={'Concentrated Holdings'}/></div>
                            </div>
                            <div className='cardscontainer'>
                            {cardData.map(card => (
                                <div key={card.id} className="card">
                                    {loadingState[card.key] ?
                                        <div className="spinner-container">
                                            <Spinner variant="dark" size="big"/>
                                        </div> :
                                        <>
                                            <div className="card-title">{card.title}</div>
                                            <div className="closest-matching-index">{card.closestMatchingIndex}</div>
                                            <div className="r-square">{card.rSquare}</div>
                                            <div className="tracking-error">{card.trackingError?parseFloat(card.trackingError).toFixed(2)+' %':'NA'}</div>
                                            <div className="beta">{card.beta}</div>
                                            <div className="concentrated-holding">
                                                {Array.isArray(card.concentratedHolding) ? card.concentratedHolding.join(', ') : card.concentratedHolding}
                                            </div>
                                        </>}
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>) : (
                    <>
                        <div className="shopping-cart">
                            <div className='shoppinghead'><h3>Shopping Cart</h3> <button className="ai-btn line secondary" onClick={() => setShoppingFlag(false)}> Back To Shield Recommendations</button></div>
                            <div className="shopping-card-container">
                                <ShoppingCart />
                            </div>
                        </div>
                    </>
                )
                }
            </div >
        </>
    );
};

export default ShieldRecommendation;
