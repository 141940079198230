import React, { useState, useEffect } from 'react';
// import { closeDrawer, openDrawer } from '../../actions/drawerActions';
import { useDispatch, useSelector } from 'react-redux';
import './SummaryAnalytics.scss';
import { GetMarketShieldPlus } from '../../Apis/GetMarketShieldPlus';
import Spinner from '../Spinner/Spinner';
import AnalyticsDrawer from "../../Components/AnalyticsDrawer/AnalyticsDrawer";
import { handleApiError } from '../../actions/notificationActions';
import Toaster from '../../Components/Toaster/Toaster';
import TagManager from 'react-gtm-module';
import TooltipComponent from '../TooltipComponent/TooltipComponent';
import { FetchRiskContribution } from "../../Apis/FetchRiskContribution";
import { GetBacktestGraphData } from '../../Apis/GetBacktestGraphData';
import BacktestV2Graph from '../BackTestV2/BackTestV2Graph';
import moment from 'moment';

function SummaryAnalytics({
    portfolioId,
    isEmpty,
    isGeneratingPdf,
    activeTabLabel,
  }) {
    const dispatch = useDispatch();
    const [loadingState, setLoadingState] = useState({
        marketShield: false,
        riskContributionData: false,
        backtestData: false
    });

    const [shields, setShields] = useState({
        marketShield: {
            protectionLevel: 95,
            protectionPeriod: "3 Month",
            assetAllocation: "90-10",
        }
    });
    const [marketShieldData, setMarketShieldData] = useState([])
    const totalPremium = sessionStorage.getItem('totalPremium');
    const totalCost = sessionStorage.getItem('totalCost');
    const portfolioDetails = useSelector(state => state.portfolioDetails[portfolioId].details);
    const [riskContributionData, setRiskContributionData] = useState([]);
    const [backtestData, setBacktestData] = useState([]);
    const [totalMarketCont, setTotalMarketCont] = useState(0);
    const [totalIdioCont, setTotalIdioCont] = useState(0);
    const [totalIsoCont, setTotalIsoCount] = useState(0);
    const [highIdioStocks, setHighIdioStocks] = useState(0);



    var currObj = {
        style: "currency",
        currency: "USD",
    };

    useEffect(() => {
        if (portfolioId && !isEmpty && (activeTabLabel === "Summary Analytics" || isGeneratingPdf)) {
            // Fetch and dispatch logic
            setLoadingState(prev => ({ ...prev, riskContributionData: true }));
            if (riskContributionData.length > 0) {
                setLoadingState(prev => ({ ...prev, riskContributionData: false }));
            } else {
                FetchRiskContribution(portfolioId).then((data) => {
                    if (data.status === 200) {
                        setRiskContributionData(data)
                        let totalMarketContribution = 0;
                        let totalIdiosyncraticPortion = 0;
                        let totalIsolatedRisk = 0;
                        let highIdiosyncraticRiskItems = [];

                        for (const [key, value] of Object.entries(data.result.risk_contribution)) {
                            totalMarketContribution += value.market_contribution;
                            totalIdiosyncraticPortion += value.idiosyncratic_portion;
                            totalIsolatedRisk += value.isolated_risk;

                            if (value.idiosyncratic_portion > 0.03) {
                                highIdiosyncraticRiskItems.push(key);
                            }
                        }
                        setTotalMarketCont(totalMarketContribution)
                        setTotalIdioCont(totalIdiosyncraticPortion)
                        setTotalIsoCount(totalIsolatedRisk)
                        if (highIdiosyncraticRiskItems.length == 0) {
                            highIdiosyncraticRiskItems.push("-")
                        }
                        setHighIdioStocks(highIdiosyncraticRiskItems)
                        setLoadingState(prev => ({ ...prev, riskContributionData: false }));
                    }
                    else {
                        handleApiError(data, dispatch);
                        setLoadingState(prev => ({ ...prev, riskContributionData: false }));
                    }
                });
            }
        }
    }, [isEmpty, portfolioId]);

    useEffect(() => {
        if((activeTabLabel === "Summary Analytics" || isGeneratingPdf)){
            const startDate = new Date(new Date().setMonth(new Date().getMonth() - 12))
            const endDate = new Date(new Date().setDate(new Date().getDate() - 7))
            const date1 = startDate.toISOString().split("T")[0].toString();
            const date2 = endDate.toISOString().split("T")[0].toString();
            if (portfolioId && !isEmpty) {
                // Fetch and dispatch logic
                setLoadingState(prev => ({ ...prev, backtestData: true }));
                if (backtestData.length > 0) {
                    setLoadingState(prev => ({ ...prev, backtestData: false }));
                } else {
                    GetBacktestGraphData(portfolioId, date1, date2, 0.95, 91).then((data) => {

                        setBacktestData(data)
                        setLoadingState(prev => ({ ...prev, backtestData: false }));

                    });
                }
            }
        }
    }, [isEmpty, portfolioId]);


    useEffect(() => {
        if((activeTabLabel === "Summary Analytics" || isGeneratingPdf)){
            if (isEmpty) return;
            setLoadingState(prev => ({ ...prev, marketShield: true }));
            let formattedDate = moment(moment().add(2, 'month').toDate()).format('YYYY-MM-DD');
            GetMarketShieldPlus(portfolioId,formattedDate).then((data) => {
                if (data.status === 200) {
                    setMarketShieldData(data.result);
                    setLoadingState(prev => ({ ...prev, marketShield: false }));
                } else {
                    handleApiError(data, dispatch);
                    setLoadingState(prev => ({ ...prev, marketShield: false }));
                }
            }).catch(error => {
                console.error("Error fetching market shield data:", error);
                handleApiError(error, dispatch);
                setLoadingState(prevState => ({ ...prevState, marketShield: false }));
            });
        }
    }, [isEmpty, portfolioId, shields.marketShield.protectionPeriod]);

    return (
        <>
            {!isEmpty ? (
                <>
                <div className="toast-container">
                    <Toaster />
                </div>
                <div className="drawercontent">
    
                    <div className='shieldrecc'>
                        <div className="card-container">
                            <div className="header-card">
                                <div>MARKET VALUE HEDGEABLE ($)</div>
                                <div>NUMBER OF HOLDINGS</div>
                                <div>PORTFOLIO VOLATILITY (ANNUALIZED)</div>
                                <div>INDEX VOLATILITY (HISTORICAL)</div>
                                <div>BEST MATCH INDEX</div>
                                <div>R-SQUARE</div>
                                <div>BETA</div>
                                <div>TRACKING ERROR</div>
                            </div>
                            {
                                <div key={1} className="card small">
                                    {loadingState["riskContributionData"] && loadingState["marketShield"] ?
                                        <div className="spinner-container">
                                            <Spinner variant="dark" size="big"/> 
                                        </div> :
                                        <>
                                            <div>{portfolioDetails?.TotalPortfolioValue ? `$${portfolioDetails.TotalPortfolioValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : 'N/A'}</div>
                                            <div>{(portfolioDetails?.PortfolioLineItem?.Hedgeable?.length ?? 0) + (portfolioDetails?.PortfolioLineItem?.Unhedgeable?.length ?? 0)}</div>
                                            <div>{riskContributionData?.result?.portfolio_risk?.toFixed(2) ?? 'N/A'}</div>
                                            <div>{riskContributionData?.result?.historical_market_risk?.toFixed(2) ?? 'N/A'}</div>
                                            <div>{riskContributionData?.result?.selected_index ?? 'N/A'}</div>
                                            <div>{marketShieldData?.RSq ?? 'N/A'}</div>
                                            <div>{marketShieldData?.Beta?.toFixed(2) ?? 'N/A'}</div>
                                            <div>{marketShieldData?.TrackingError?.toFixed(2) ?? 'N/A'}</div>
                                        </>
                                    }
                                </div>
                            }
                            {(Object.keys(backtestData).length > 0) &&
                                <div id='backtestGraph' className="ai-backtest-graph">
                                    {loadingState["backtestData"] ?
                                        <div className="spinner-container">
                                            <Spinner variant="dark" size="big"/> 
                                        </div>:
                                        <BacktestV2Graph rawData={backtestData} showPortfolioShield={false} summary={false} />
                                    }
                                </div>}
                        </div>
    
                        <div className="card-container">
                            <div className="header-card">
                                <div>MARKET RISK CONTRIBUTION</div>
                                <div>IDIOSYNCRATIC RISK CONTRIBUTION</div>
                                <div>DIVERSIFICATION BENEFIT</div>
                                <div>HOLDINGS WITH HIGH IDIOSYNCRATIC RISK</div>
                            </div>
                            {
                                <div key={1} className="card small">
                                    {loadingState["riskContributionData"] && loadingState["marketShield"] ?
                                        <div className="spinner-container">
                                            <Spinner variant="dark" size="big"/> 
                                        </div> :
                                        <>
                                            {/* <div></div> */}
                                            <div>{`${totalMarketCont.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</div>
                                            <div>{`${totalIdioCont.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</div>
                                            <div>{`${totalIsoCont.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</div>
                                            <div>{highIdioStocks}</div>
                                        </>
                                    }
                                </div>
                            }
                        </div>
    
                        <div className="card-container">
                            <div className="header-card">
                                <div>PORTFOLIO IMPLIED VOLATILITY</div>
                                <div>MARKET IMPLIED VOLATILITY</div>
                            </div>
                            {
                                <div key={1} className="card small">
                                    {loadingState["riskContributionData"] && loadingState["marketShield"] ?
                                        <div className="spinner-container">
                                            <Spinner variant="dark" size="big"/> 
                                        </div> :
                                        <>
                                            {/* <div></div> */}
                                            <div>{riskContributionData?.result?.port_implied_vol?.toFixed(2) ?? 'N/A'}</div>
                                            <div>{riskContributionData?.result?.market_implied_level?.toFixed(2) ?? 'N/A'}</div>
                                        </>
                                    }
                                </div>
                            }
                        </div>
    
                        <div className="card-container">
                            <div className="header-card">
                                <div>COST OF MARKET SHIELD (INDEX HEDGE) ($)</div>
                                <div>EXPIRATION (DAYS TO MATURITY)</div>
                                <div>ADJUSTED PROTECTION LEVEL ON THE INDEX</div>
                            </div>
                            {
                                <div key={1} className="card small">
                                    {loadingState["riskContributionData"] && loadingState["marketShield"] ?
                                        <div className="spinner-container">
                                            <Spinner variant="dark" size="big"/> 
                                        </div> :
                                        <>
                                            {/* <div></div> */}
                                            <div>
                                                {`$${(marketShieldData?.ChoosenOption?.quarter?.[0.95]?.Cost ?? 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                                                ({((marketShieldData?.ChoosenOption?.quarter?.[0.95]?.Cost ?? 0) / (portfolioDetails?.TotalPortfolioValue ?? 1) * 100).toFixed(2)}%)
                                            </div>
                                            <div>{marketShieldData?.ChoosenOption?.quarter?.[0.95]?.ExpDtInDays ?? 'N/A'}</div>
                                            <div>{(marketShieldData?.ChoosenOption?.quarter?.[0.95]?.ProtLevelNew ?? 0).toFixed(2) ?? 'N/A'}</div>
                                        </>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
            ):(
                <div className="drawercontent">
                    <p>Portfolio is Empty. Please add stock to proceed</p>
                </div>
            )}
        </>
    );

};

export default SummaryAnalytics;
